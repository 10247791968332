export enum UserLocales {
  CS_CZ = 'cs-CZ',
  DE_DE = 'de-DE',
  DE_CH = 'de-CH',
  DE_AT = 'de-AT',
  DE_LI = 'de-LI',
  DE_LU = 'de-LU',
  EN_GB = 'en-GB',
  FR_FR = 'fr-FR',
  FR_CH = 'fr-CH',
  FR_LU = 'fr-LU',
  IT_IT = 'it-IT',
  IT_CH = 'it-CH'
}
