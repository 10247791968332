export enum AlcedoUserLanguages {
  CZECH = 'cs',
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it'
}

export enum IotUserLanguages {
  CZECH = 'cs',
  DUTCH = 'nl',
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it'
}

export enum TenantsUserLanguages {
  DUTCH = 'nl',
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it'
}
